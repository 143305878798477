<template>
  <b-card :title="title">
    <fictitious-bookings-table :items="items" :loading="loading" show-search />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import FictitiousBookingsTable from "@/views/bookings/fictitious/components/FictitiousBookingsTable.vue";

export default {
  components: {
    BCard,
    FictitiousBookingsTable,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    loading() {
      return this.$store.getters["bookings/loading"];
    },
  },
};
</script>
