<template>
  <div id="fictitious-bookings">
    <b-tabs no-fade>
      <!-- PENDING BOOKINGS -->
      <b-tab active>
        <template #title>
          <span class="d-none d-lg-block">Pendents</span>
        </template>
        <fictitious-bookings-list
          :title="fictitiousBookingsListTitle"
          :items="parsedFictitiousBookings"
        />
      </b-tab>
      <!-- CONFIRMED -->
      <b-tab>
        <template #title>
          <span class="d-none d-lg-block">Confirmades</span>
        </template>
        <fictitious-bookings-list
          :title="bookingsFromFictitiousListTitle"
          :items="parsedBookingsFromFictitious"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import FictitiousBookingsList from "@/views/bookings/fictitious/components/FictitiousBookingsList.vue";
import { notifyError } from "@/utils/methods";
import {
  formatCurrency,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";

export default {
  components: {
    BTabs,
    BTab,
    FictitiousBookingsList,
  },
  computed: {
    fictitiousBookings() {
      return this.$store.getters["bookings/fictitiousBookings"];
    },
    parsedFictitiousBookings() {
      if (!this.fictitiousBookings.length) return [];
      return this.fictitiousBookings.map((booking) => {
        return {
          uuid: booking.uuid || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          status: booking.status || null,
          source: booking.source || null,
          highlighted: booking.highlighted || false,
          fictitious: booking.fictitious || false,
          client: booking.client?.fullName || this.$t("No definit"),
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          checkinOrder: formatDateStringToDatabaseDate(booking.checkin) || null,
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutOrder:
            formatDateStringToDatabaseDate(booking.checkout) || null,
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          freeCancellationLimitDateOrder:
            formatDateStringToDatabaseDate(booking.freeCancellationLimitDate) ||
            null,
          freeCancellationLimitDate:
            formatDateStringToDate(booking.freeCancellationLimitDate) ||
            this.$t("No definit"),
          amount: this.getPendingAmount(booking) || this.$t("No definit"),
        };
      });
    },
    fictitiousBookingsListTitle() {
      return `Llistat de reserves fictícies pendents (${this.parsedFictitiousBookings.length})`;
    },
    bookingsFromFictitious() {
      return this.$store.getters["bookings/bookingsFromFictitious"];
    },
    parsedBookingsFromFictitious() {
      if (!this.bookingsFromFictitious.length) return [];
      return this.bookingsFromFictitious.map((booking) => {
        return {
          uuid: booking.uuid || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          status: booking.status || null,
          source: booking.source || null,
          highlighted: booking.highlighted || false,
          fictitious: booking.fictitious || false,
          client: booking.client?.fullName || this.$t("No definit"),
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          checkinOrder: formatDateStringToDatabaseDate(booking.checkin) || null,
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutOrder:
            formatDateStringToDatabaseDate(booking.checkout) || null,
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          freeCancellationLimitDateOrder:
            formatDateStringToDatabaseDate(booking.freeCancellationLimitDate) ||
            null,
          freeCancellationLimitDate:
            formatDateStringToDate(booking.freeCancellationLimitDate) ||
            this.$t("No definit"),
          amount: this.getPendingAmount(booking) || this.$t("No definit"),
        };
      });
    },
    bookingsFromFictitiousListTitle() {
      return `Llistat de reserves obtingudes desde fictícies (${this.parsedBookingsFromFictitious.length})`;
    },
  },
  created() {
    this.fetchFictitiousBookings();
    this.fetchBookingsFromFictitious();
  },
  methods: {
    fetchFictitiousBookings() {
      this.$store.dispatch("bookings/fetchFictitiousBookings").catch(() => {
        notifyError(
          this.$t("errors.fetchBookings.title"),
          this.$t("errors.fetchBookings.description")
        );
      });
    },
    fetchBookingsFromFictitious() {
      this.$store.dispatch("bookings/fetchBookingsFromFictitious").catch(() => {
        notifyError(
          this.$t("errors.fetchBookings.title"),
          this.$t("errors.fetchBookings.description")
        );
      });
    },
    getPendingAmount(booking) {
      if (!booking) return null;

      const bookingPayments = booking.clientPayments.filter(
        (clientPayment) =>
          clientPayment.scope === "BOOKING" &&
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            clientPayment.status
          )
      );

      let paidAmount = 0;
      if (bookingPayments.length) {
        paidAmount = bookingPayments.reduce(
          (acc, clientPayment) => acc + clientPayment.pvpAmount,
          0
        );
      }

      const pending = booking.ratesPrice - paidAmount;

      return pending ? formatCurrency(pending) : null;
    },
  },
};
</script>
